<template>
  <AutoComplete
      v-model="internalModel"
      :suggestions="filteredContacts"
      @complete="searchContact"
      @item-select="onItemSelect"
      @clear="onItemClear"
      :forceSelection="forceSelection"
      field="name"
      :minLength=3
      :disabled="disabled"
      :placeholder="disabled ? '' : 'Search contact...'"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return ""
      }
    },
    is_customer: Boolean,
    is_supplier: Boolean,
    is_agent: Boolean,
    is_individual: Boolean,
    forceSelection: {
      type: Boolean,
      default: false
    },
    disabled: {  // Declaring the disabled prop
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (typeof newValue === 'string' && !this.isContactInList(newValue)) {
          const customValue = {contact_id: null, name: newValue};
          this.$emit('input', customValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },
  data() {
    return {
      filteredContacts: [],
    };
  },
  methods: {
    async searchContact(event) {
      if (!event.query.trim()) return;

      try {
        const queryParams = this.buildQueryParams(event.query);
        const response = await this.ContactService.searchContactsWithType(queryParams);
        this.filteredContacts = response;
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    },
    buildQueryParams(searchTerm) {
      return {
        searchTerm,
        is_customer: this.is_customer,
        is_supplier: this.is_supplier,
        is_agent: this.is_agent,
        is_individual: this.is_individual
      };
    },
    isContactInList(name) {
      // Check if the name is in the list of filtered contacts
      return this.filteredContacts.some(contact => contact.name === name);
    },
    onItemSelect(event) {
      this.$emit('contactSelected', event);
    },
    onItemClear(event) {
      this.$emit('contactCleared', event);
    },
  }
};
</script>

<style>
/* Your styles */
</style>
