<template>
  <div class="m-4 flex flex-col max-w-sm" :class="bgColor">
    <div class="flex justify-between py-2 px-4" :class="headerBgColor">
      <h3 class="text-lg font-bold" :class="textColor">{{ title }}</h3>
      <button @click="close" :class="textColor" class="ml-4">
        <svg
          class="stroke-current h-6 w-6"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M18 6L6 18M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="py-2 px-4" :class="textColor">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      textColor: "",
      bgColor: "",
      headerBgColor: ""
    };
  },
  props: {
    type: {
      type: String,
      default: "success"
    },
    title: {
      type: String,
      required: true
    },
    close: Function
  },
  created() {
    switch (this.type) {
      default:
        this.textColor = "text-green-900";
        this.bgColor = "bg-green-200";
        this.headerBgColor = "bg-green-300";
        break;
      case "warning":
        this.textColor = "text-yellow-900";
        this.bgColor = "bg-yellow-200";
        this.headerBgColor = "bg-yellow-300";
        break;
      case "danger":
        this.textColor = "text-red-900";
        this.bgColor = "bg-red-200";
        this.headerBgColor = "bg-red-300";
        break;
    }
  }
};
</script>