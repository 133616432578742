<template>
  <form
      @submit.prevent="submitCertificate"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getCertificateNo" :enableLogicalBack="true" back-to=" ">

      <AppButton color="indigo" label="Print Certificate" @click="printCertificate" :icon=true class="mr-2">
        <template v-slot:icon>
          <Icon iconType="print" class="mr-2"/>
        </template>
      </AppButton>

      <button
          @click="saveCertificate"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Save Certificate</span>
      </button>
    </page-header>


    <main class="pr-6 pl-6  bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Equipment Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
            <AppButton color="green" label="Find & Select Equipment" :icon=true class="ml-2"
                       @click="clickFindEquipmentPanel()">
              <template v-slot:icon>
                <Icon iconType="search" class="mr-2"/>
              </template>
            </AppButton>
          </div>

          <div class="flex flex-col bg-white md:flex-row items-center justify-between ">


            <!-- Main Column 1 -->
            <div class=" w-full sm:w-full md:w-4/9 bg-white mx-4 rounded md:rounded-none">
              <div class="w-full flex">
                <!-- Sub Column 1 of 1 -->
                <div class="w-4/12 p-1 px-2">
                  <!-- Row 1 -->
                  <div class="">
                    <label class="uppercase font-semibold text-sm">Serial NUMBER</label>

                    <EquipmentWithSerialNumberAutoComplete class="w-full" @equipmentSelected="handleEquipmentSelected"
                                                           @equipmentCleared="handleEquipmentCleared"
                                                           @equipmentUnselected="handleEquipmentUnselected"
                                                           v-model="certificate.equipment"
                    />

                  </div>
                  <!-- Row 2 -->
                  <div class="mt-4"> <!-- Added margin-top for alignment -->
                    <label class="uppercase font-semibold text-sm">Equipment Model</label>
                    <input class="w-full bge-input bge-input-spacing rounded" disabled
                           v-model="certificate.equipment_model"
                           type="text"/>
                  </div>

                </div>

                <!-- Sub Column 1 of 2 -->
                <div class="w-8/12 p-1 px-2">
                  <!-- Row 1 - Split into two parts -->
                  <div class="flex">
                    <div class="w-5/12 px-2"> <!-- Adjusted to 80% -->
                      <label class="uppercase font-semibold text-sm">Make</label>
                      <input class="w-full bge-input bge-input-spacing rounded" v-model="certificate.equipment_make"
                             type="text"/>
                    </div>
                    <div class="w-4/12 px-2"> <!-- Adjusted to 20% -->
                      <label class="uppercase font-semibold text-sm sw-label">SW Version</label>
                      <input class="w-full bge-input bge-input-spacing rounded" v-model="certificate.sw_version"
                             type="text"/>
                    </div>

                    <div class="w-3/12 px-2 flex flex-col justify-center items-center"> <!-- Adjusted to 20% -->
                      <label class="uppercase font-semibold text-sm">Connected</label>
                      <Toggle class="w-full override-toggle  bge-input-spacing rounded pl-4"
                              labelPosition="left"
                              label=""
                              :checked="certificate.is_connected"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => certificate.is_connected = value"
                      />
                    </div>
                  </div>
                  <!-- Row 2 -->
                  <div class="mt-4 mx-2"> <!-- Added margin-top for alignment -->
                    <label class="uppercase font-semibold text-sm">Description</label>
                    <input class="w-full bge-input bge-input-spacing rounded"
                           v-model="certificate.equipment_description"
                           type="text"/>
                  </div>
                </div>
              </div>
            </div>

            <div class=" md:block">
              <div class="w-px bg-orange-500 md:h-32"></div>
            </div>

            <!-- Main Column 2 -->
            <div class=" w-full md:w-3/9 sm:w-full bg-white px-2 mx-4  rounded  md:rounded-none">
              <div class="flex">
                <!-- Sub Column 2 of 1 -->
                <div class="w-8/12 px-2">
                  <!-- Row 1 -->
                  <div class="mb-2 p-1">
                    <label class="uppercase font-semibold text-sm">Engineer</label>
                    <StaffAutocomplete class="w-full" v-model="certificate.engineer" :forceSelection="true"/>
                  </div>
                  <!-- Row 2 -->
                  <div class="p-1">
                    <label class="uppercase font-semibold text-sm">Customer</label>
                    <ContactAutocomplete class="w-full" v-model="certificate.customer" :disabled=true
                                         :forceSelection="true" :is_customer="true"/>
                  </div>
                </div>
                <!-- Sub Column 2 of 2 -->
                <div class="w-4/12 px-2">
                  <!-- Row 1 -->
                  <div class="mb-2 p-1">
                    <div class="w-full">
                      <!-- Row 1 split into three parts -->
                      <label class="uppercase font-semibold text-sm">Eng No.</label>
                      <input class="w-full bge-input bge-input-spacing rounded" v-model="certificate.engineer_number"
                             type="text"/>
                    </div>
                  </div>
                  <!-- Row 2 -->
                  <div class="p-1">
                    <label class="uppercase font-semibold text-sm">VTS No.</label>
                    <input class="w-full bge-input bge-input-spacing rounded" v-model="certificate.customer_vts_number"
                           type="text"/>
                  </div>
                </div>
              </div>
            </div>

            <div class=" md:block">
              <div class="w-px bg-orange-500 md:h-32"></div>
            </div>

            <!-- Main Column 3 -->
            <div class="w-full md:w-2/9 bg-white mx-4  rounded md:rounded-none">
              <div class="flex flex-wrap w-full">
                <!-- Row 1: Internal and Issue Date -->
                <div class="w-full flex items-start">
                  <!-- Internal Section -->
                  <div class="w-3/10 p-1 flex flex-col justify-center">
                    <div class="flex flex-col justify-center items-center">
                      <label class="block uppercase font-semibold text-sm mb-2">Internal</label>
                      <Toggle class="rounded override-toggle pl-2"
                              labelPosition="left"
                              label=""
                              :checked="certificate.is_internal"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => {
              certificate.is_internal = value;
              if (value) certificate.is_external = !value;
            }"
                      />
                    </div>
                  </div>


                  <!-- Issue Date Section -->
                  <div class="w-7/10 p-1 flex flex-col">
                    <label class="block uppercase font-semibold text-sm">Issue Date</label>
                    <Calendar appendTo="body" class="w-full"
                              dateFormat="dd/mm/yy"
                              v-model="certificate.issue_date" :showIcon="true"
                    />
                  </div>
                </div>

                <!-- Row 2: External and Expiry Date -->
                <div class="w-full flex items-start mt-3">
                  <!-- External Section -->
                  <div class="w-3/10 p-1 flex flex-col justify-center items-center">
                    <label class="block uppercase font-semibold text-sm">External</label>
                    <Toggle class="mt-2 rounded override-toggle pl-2"
                            labelPosition="left"
                            label=""
                            :checked="certificate.is_external"
                            labelClasses="uppercase font-semibold text-sm"
                            @value-changed="(value) => {
            certificate.is_external = value;
            if (value) certificate.is_internal = !value;
          }"
                    />
                  </div>

                  <!-- Expiry Date Section -->
                  <div class="w-7/10 p-1 flex flex-col">
                    <label class="block uppercase font-semibold text-sm">Expiry Date</label>
                    <Calendar appendTo="body" class="w-full"
                              dateFormat="dd/mm/yy"
                              v-model="certificate.expiry_date" :showIcon="true"
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>


      <component :is="current_certificate_template" :calibration_details="certificate" ref="certificate_form"
                 class="mt-4"></component>
      <!--      <ExhaustGasAnalyser ref="exhaustGasAnalyserCalibrationComponent"-->
      <!--                          :calibration_details="certificate"/>-->

      <!--      <SmokeMeter ref="smokeMeterCalibrationComponent" :calibration_details="certificate.smoke_meter"/>-->

      <portal to="overlay-outlet">
        <PDFViewer :showing="pdfViewerData.isShowingViewer" :pdfBlob="pdfViewerData.pdfData"
                   :documentName="pdfViewerData.documentName" @close="closeViewer"/>
      </portal>

    </main>
    <portal to="overlay-outlet">
      <panel
          :showing="findEquipmentPanelOpen"
          @close="findEquipmentPanelOpen = false"
          title="Find Equipment"
      >
        <FindEquipment
            @complete="applyEquipmentDetails"
        />
      </panel>
    </portal>

  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import EquipmentWithSerialNumberAutoComplete from '@/components/AutoComplete/EquipmentWithSerialNumberAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import printJS from "print-js";

const Toggle = () => import("@/components/Toggle.vue");
const PDFViewer = () => import("@/components/PDFViewer.vue");

import ExhaustGasAnalyser from './Components/ExhaustGasAnalyser.vue'
import SmokeMeter from './Components/SmokeMeter.vue'
import Decelerometer from './Components/Decelerometer.vue'
import RollerBrakeTester from './Components/RollerBrakeTester.vue'
import RollerBrakeTesterMC from './Components/RollerBrakeTesterMC.vue'
import Statement from './Components/Statement.vue'
import LoadProof from './Components/LoadProofCertificatet.vue'
import ExaminationVL from './Components/ExaminationVL.vue'
import ServiceReport from './Components/ServiceReport.vue'
import FindEquipment from "@/views/HR/Engineer/Certificate/Components/FindEquipment.vue";


const CERTIFICATE_CONFIG = {
  'calibration - ags': {
    component: ExhaustGasAnalyser,
    dataField: 'exhaust_gas_analyser',
    resetData: () => ({exhaust_gas_analyser: null}),
    reportValid: true
  },
  'calibration - opa': {
    component: SmokeMeter,
    dataField: 'smoke_meter',
    resetData: () => ({smoke_meter: null}),
    reportValid: true
  },
  'calibration - dc7500': {
    component: Decelerometer,
    dataField: 'decelerometer',
    resetData: () => ({decelerometer: null}),
    reportValid: true
  },
  'calibration - rbt': {
    component: RollerBrakeTester,
    dataField: 'roller_brake_tester',
    resetData: () => ({roller_brake_tester: null}),
    reportValid: true
  },
  'calibration - rbt-mc': {
    component: RollerBrakeTesterMC,
    dataField: 'roller_brake_tester_mc',
    resetData: () => ({roller_brake_tester_mc: null}),
    reportValid: true
  },
  'calibration - hlt': {
    component: Statement,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: true
  },
  'calibration - pd': {
    component: Statement,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: false
  },
  'examination - vl': {
    component: ExaminationVL,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: true
  },
  'examination - all': {
    component: Statement,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: true
  },
  'examination certificate': {
    component: Statement,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: false
  },
  'service': {
    component: Statement,
    dataField: 'statement',
    resetData: () => ({statement: null}),
    reportValid: true
  },
  'load proof - vl': {
    component: LoadProof,
    dataField: 'statement',
    resetData: () => ({statement: null, load_proof: null}),
    reportValid: true
  },
  'service - report': {
    component: ServiceReport,
    dataField: 'service_report',
    resetData: () => ({service_report: null}),
    reportValid: true
  }
};


export default {
  name: "ManageCertificate",
  mixins: [notificationMixin],
  components: {
    FindEquipment,
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    EquipmentWithSerialNumberAutoComplete,
    AppButton,
    Icon,
    Toggle,
    PDFViewer,
    ExhaustGasAnalyser,
    SmokeMeter,
    Decelerometer,
    RollerBrakeTester,
    RollerBrakeTesterMC,
    Statement,
    LoadProof,
    ServiceReport,
    ExaminationVL

  },
  props: {
    certificate_id: String,
  },
  data() {
    return {
      isBusy: false,
      current_certificate_template: null,
      current_certificate_type: null,
      report_type: "",
      findEquipmentPanelOpen: false,
      certificate: {
        equipment: {
          equipment_id: '',
          serial_number: '',
        },
        is_internal: false,
        is_external: false,
        is_connected: false,
        load_proof: {},
        exhaust_gas_analyser:
            {
              initial_calibration: {
                calibration_gas: {},
                gas_pef: {},
                analyser_reading: {},
              },
              final_calibration: {
                calibration_gas: {},
                gas_pef: {},
                analyser_reading: {},
              }
            },

        smoke_meter: {
          initial_calibration: {
            ambient_temperature: null,
            garage_filter: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_1: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_2: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_3: {
              true_filter_value: null,
              smoke_meter_reading: null
            }
          },
          final_calibration: {
            ambient_temperature: null,
            garage_filter: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_1: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_2: {
              true_filter_value: null,
              smoke_meter_reading: null
            },
            reference_filter_3: {
              true_filter_value: null,
              smoke_meter_reading: null
            }
          }
        },
      },
      pdfViewerData: {
        documentName: "",
        pdfData: null,
        isShowingViewer: false,
      },
      pdfUrl: null

    };
  },
  computed: {
    pageTitle() {
      return this.certificate_id ? "Update Certificate" : "Create Certificate";
    },

    getCertificateNo() {
      return this.certificate && this.certificate.certificate_number ? this.certificate.certificate_number : "";
    }
  },
  async mounted() {

    this.isBusy = true;
    if (this.certificate_id) {
      const certificate = await this.loadCertificateById();
      this.certificate = this.formatCertificateData(certificate);
      this.loadCertificate();
      this.isBusy = false;
      return;
    }

    this.isBusy = false;
  },
  methods: {
    async clickFindEquipmentPanel() {
      this.findEquipmentPanelOpen = true;
    },
    async applyEquipmentDetails(data) {
      if (!data) {
        return;
      }
      this.certificate.equipment_model = data.product_code || '';
      this.certificate.equipment = {
        equipment_id: data.equipment_id || '',
        serial_number: data.serial_number || '',
      };
      this.certificate.equipment_description = data.name || '';

      if (data.owner) {
        this.certificate.customer = {};
        this.certificate.customer.name = data.owner.name || '';
        this.certificate.customer.contact_id = data.owner.contact_id || '';
      }
      if (data.certificate_type) {
        this.certificate.type = {
          data_master_id: data.certificate_type.data_master_id,
          name: data.certificate_type.name
        }
      }

      if (data.certificate_type &&
          Array.isArray(data.certificate_type.service_checks) &&
          data.certificate_type.service_checks.length > 0) {

        if (!this.certificate.service_report) {
          this.$set(this.certificate, 'service_report', {});
        }
        this.$set(this.certificate.service_report, 'service_questions', data.certificate_type.service_checks);

      }

      await this.handleTypeChange();

      if (data.certificate_type && data.certificate_type.description) {
        this.$set(this.certificate, 'statement', data.certificate_type.description);
      }


      this.updateCertificateDates(data);


      this.findEquipmentPanelOpen = false;
    },
    async handleEquipmentSelected(selectedEquipment) {
      this.isBusy = true;
      this.certificate.equipment_model = selectedEquipment.value.product_code;
      this.certificate.equipment_description = selectedEquipment.value.name;
      if (!selectedEquipment || !selectedEquipment.value) {
        this.isBusy = false;
        return;
      }

      const equipmentDetails = await this.EquipmentService.getEquipmentItemById(selectedEquipment.value.equipment_id)

      if (!equipmentDetails || !equipmentDetails.owner) {
        this.isBusy = false;
        return;
      }

      const customer = {
        contact_id: equipmentDetails.owner.contact_id,
        name: equipmentDetails.owner.name
      };
      this.certificate.customer = customer;
      this.updateCertificateDates(equipmentDetails);

      this.isBusy = false;
    },

    updateCertificateDates(equipmentDetails) {
      const todayMoment = this.$moment();
      const todayDate = todayMoment.toDate();
      this.certificate.issue_date = todayDate;

      if (!equipmentDetails || equipmentDetails.calibration_interval == null) {
        this.certificate.expiry_date = todayDate;
      } else {
        const expiryMoment = todayMoment.clone().add(equipmentDetails.calibration_interval, 'months');
        const expiryDate = expiryMoment.toDate();
        this.certificate.expiry_date = expiryDate;
      }
    },
    handleEquipmentCleared() {
      this.certificate.equipment_model = null;
      this.certificate.equipment_description = null;
      this.certificate.customer = null;
    },
    handleEquipmentUnselected() {
      this.certificate.equipment_model = null;
      this.certificate.equipment_description = null;
      this.certificate.customer = null;
    },

    loadCertificate() {
      if (!this.certificate || !this.certificate.type || !this.certificate.type.name) {
        this.notifyWarning("Invalid certificate data. Missing certificate type information.");
        return;
      }


      const certificate_type = this.certificate.type.name.trim().toLowerCase();
      this.current_certificate_type = this.certificate.type;
      const typeConfig = CERTIFICATE_CONFIG[certificate_type];

      if (typeConfig) {
        this.current_certificate_template = typeConfig.component;
      } else {
        console.warn(`No template available for the certificate type: ${certificate_type}`);
        this.current_certificate_template = null;
      }

      if (!this.current_certificate_template) {
        this.notifyWarning("Failed to load certificate template. Please check the certificate type.");
      }
    },

    async handleTypeChange() {

      const new_certificate_type_name = this.certificate && this.certificate.type && this.certificate.type.name
          ? this.certificate.type.name.trim().toLowerCase()
          : '';


      const current_certificate_type_name = this.current_certificate_type && this.current_certificate_type.name
          ? this.current_certificate_type.name.trim().toLowerCase()
          : '';


      if (current_certificate_type_name && current_certificate_type_name !== new_certificate_type_name && this.modelHasData(current_certificate_type_name)) {
        const confirmation = await this.confirmAction({
          message: 'Changing the model will reset the existing calibration data for other models. Do you want to continue?',
          header: 'Confirm Model Change',
          acceptLabel: 'Continue',
          rejectLabel: 'Cancel',
        });
        if (!confirmation) {
          this.certificate.type = this.current_certificate_type;
          return;
        }

        this.resetOtherModelData(new_certificate_type_name);
      }

      this.current_certificate_type = this.certificate.type;
      this.loadCertificate(new_certificate_type_name);
    },


    modelHasData(type) {
      const certificate_type = type.toLowerCase();
      const config = CERTIFICATE_CONFIG[certificate_type];
      if (config && this.certificate[config.dataField] !== null) {
        return true;
      }
      return false;
    },

    resetOtherModelData(excludeType) {
      Object.entries(CERTIFICATE_CONFIG).forEach(([type, config]) => {
        if (type !== excludeType) {
          Object.assign(this.certificate, config.resetData());
        }
      });
    },


    async loadCertificateById() {
      try {
        const certificateData = await this.CertificateService.getCertificateById(this.certificate_id);
        return certificateData;
      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    formatCertificateData(certificate) {

      let formattedCertificate = JSON.parse(JSON.stringify(certificate));

      // Formatting job_start_date
      if (formattedCertificate.issue_date) {
        let issueMoment = this.$moment.unix(formattedCertificate.issue_date);
        formattedCertificate.issue_date = issueMoment.toDate();
      }

      // Formatting job_end_date
      if (formattedCertificate.expiry_date) {
        let expiryMoment = this.$moment.unix(formattedCertificate.expiry_date);
        formattedCertificate.expiry_date = expiryMoment.toDate();
      }

      if (formattedCertificate.scope != null) {
        switch (formattedCertificate.scope) {
          case 'external':
            formattedCertificate.is_external = true;
            formattedCertificate.is_internal = false;
            break;
          case 'internal':
            formattedCertificate.is_internal = true;
            formattedCertificate.is_external = false;
            break;
          default:
            break;
        }
      }

      return formattedCertificate;
    },

    unformatCertificateData(formattedCertificate) {
      let certificate = JSON.parse(JSON.stringify(formattedCertificate));


      // Reverting issue_date
      if (certificate.issue_date) {
        certificate.issue_date = this.$moment(certificate.issue_date).unix();
      }

      // Reverting expiry_date
      if (certificate.expiry_date) {
        certificate.expiry_date = this.$moment(certificate.expiry_date).unix();
      }

      // Reverting scope
      if (certificate.is_internal != null || certificate.is_external != null) {
        if (certificate.is_internal) {
          certificate.scope = 'internal';
        } else if (certificate.is_external) {
          certificate.scope = 'external';
        } else {
          certificate.scope = null;
        }
        delete certificate.is_internal;
        delete certificate.is_external;
      }


      const type = certificate.type.name.trim().toLowerCase();
      const config = CERTIFICATE_CONFIG[type];
      if (!config) {
        console.error('Configuration not found for certificate type:', type);
        return;
      }

      Object.keys(CERTIFICATE_CONFIG).forEach(certType => {
        const field = CERTIFICATE_CONFIG[certType].dataField;
        if (field !== config.dataField) {
          certificate[field] = null;
        }
      });

      certificate[config.dataField] = this.$refs.certificate_form.getCurrentData();

      return certificate;
    },


    async printCertificate() {

      if (!this.certificate_id) {
        this.notifyWarning("Missing certificate ID . Please ensure all required fields are filled.");
        return;
      }


      if (!this.certificate || !this.certificate.type || !this.certificate.type.name) {
        this.notifyWarning("Invalid certificate data. Missing certificate type information.");
        return;
      }

      const certificate_type = this.certificate.type.name.trim().toLowerCase();


      if (!this.isValidReportType(certificate_type)) {
        this.notifyWarning("Invalid report type. Please select a valid report type before printing.");
        return;
      }

      try {
        const response = await this.CertificateService.getReport(this.certificate_id, certificate_type);

        this.pdfViewerData = {
          pdfData: response,
          documentName: `${this.report_type.replace(/-/g, ' ')} Report`,
          isShowingViewer: true,
        };
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.notifyWarning("Failed to load the PDF document. Please try again.");
      }
    },

    isValidReportType(reportType) {

      const normalizedType = reportType.trim().toLowerCase();

      return CERTIFICATE_CONFIG[normalizedType] && CERTIFICATE_CONFIG[normalizedType].reportValid;
    },

    closeViewer() {
      this.pdfViewerData = {
        pdfData: null,
        documentName: "Gas Analyzer Report",
        isShowingViewer: false,
      };
    },
    async saveCertificate() {

      if (!this.certificate || !this.certificate.type || !this.certificate.type.name) {
        this.notifyWarning("Invalid certificate data. Missing certificate type information.");
        return;
      }

      this.isBusy = true;

      // Format the certificate data before saving
      const formattedCertificate = this.unformatCertificateData(this.certificate);

      try {
        if (this.certificate_id) {
          await this.CertificateService.updateCertificate(this.certificate_id, formattedCertificate);
          this.notifySuccess("Certificate updated successfully");
        } else {
          await this.CertificateService.createCertificate(formattedCertificate);
          this.notifySuccess("Certificate created successfully");
        }
        this.$router.go(-1);

      } catch (error) {
        const errorMessage = this.certificate_id ? "Couldn't update certificate" : "Couldn't create certificate";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }

    },
    submitCertificate: async function (event) {
      event.preventDefault();
    },

  }
}
;
</script>

<style scoped>
::v-deep .override-toggle .w-full {
  width: auto !important;
}

::v-deep .override-toggle {
  width: auto !important;
}
</style>
