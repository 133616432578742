<template>
  <div class="flex flex-col lg:flex-row w-full mt-6  mb-6">
    <div class="w-full lg:w-1/2 bg-white p-5  lg:mr-3 rounded shadow-md">
      <div class="mt-6">
        <div class="flex items-center">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Certificate Data</h3>
          <hr class="flex-grow border-primary ml-4"/>

        </div>
        <div class="flex flex-wrap -my-3 mx-1 mt-4">
          <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
            <label class="uppercase font-semibold text-sm">Design Load Capacity</label>
            <input v-model="calibration_details.load_proof.design_load_capacity"
                   class="bge-input bge-input-spacing rounded"
                   name="manufacturer"
                   type="text"
            />
          </div>
        </div>

      </div>
    </div>
    <!-- Certification Statement Section -->
    <div class="w-full lg:w-1/2 bg-white p-5  lg:ml-3  rounded shadow-md">
      <div class="mt-6">
        <div class="flex items-center">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Certificate Statement</h3>
          <hr class="flex-grow border-primary ml-4"/>

          <div class="flex justify-end  ml-2">
            <AppButton v-if="!editMode" color="blue" label="" @click="enableEdit" :icon="true" class="mr-2">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton v-if="editMode" color="blue" label="" @click="saveChanges" :icon="true" class="mr-2">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editMode" color="gray" label="" @click="cancelEdit" :icon="true">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
          </div>

        </div>
        <div v-if="editMode">
          <Editor v-model="editableStatement" editorStyle="height: 120px" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                 <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
        <div v-else class="bg-gray-100 p-4 rounded-md mt-6">
          <div v-html="editableStatement"></div>
        </div>
        <!-- Buttons for editing actions -->

      </div>
    </div>
  </div>
</template>
<script>

import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  props: {
    calibration_details: {
      type: Object,
      default: () => ({statement: ''})
    }
  },
  components: {
    AppButton,
    Icon,
  },
  data() {
    return {
      editMode: false,
      editableStatement: '',
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
    };
  },
  mounted() {
    this.editableStatement = this.calibration_details.statement;
  },
  methods: {
    getCurrentData() {
      return this.editableStatement;
    },
    enableEdit() {
      this.editMode = true;
    },
    saveChanges() {
      this.editMode = false;
    },
    cancelEdit() {
      this.editMode = false;
    }
  },
  watch: {
    'calibration_details.statement'(newVal) {

      this.editableStatement = newVal;
    }
  }
}
</script>
