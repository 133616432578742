"use strict";

import store from "@/store";

export default function(permissions) {
  if (store.state.userPermissions.some((r) => permissions.includes(r))) {
    return true;
  } else {
    return false;
  }
}
