var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"flex flex-wrap"},[(_vm.equipment.length > 0)?_c('vue-good-table',{staticClass:"w-full",attrs:{"styleClass":"vgt-table condensed","mode":"remote","rows":_vm.equipment,"columns":_vm.columns,"totalRows":_vm.totalEquipmentItems,"pagination-options":{
        enabled: true,
        perPage: 50,
        perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
        dropdownAllowAll: false,
      }},on:{"on-row-click":_vm.openEquipmentDetails,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'is_loan_unit')?_c('span',[_c('span',{staticClass:"px-2 rounded-lg",class:{
              'bg-yellow-200 text-yellow-700':
                props.row.is_loan_unit == false,
              'bg-green-200 text-green-700': props.row.is_loan_unit == true,
            }},[_vm._v(_vm._s(props.row.is_loan_unit ? "Yes" : "No"))])]):(props.column.field == 'is_operational')?_c('span',[_c('span',{staticClass:"px-2 rounded-lg",class:{
              'bg-yellow-200 text-yellow-700':
                props.row.is_operational == false,
              'bg-green-200 text-green-700': props.row.is_operational == true,
            }},[_vm._v(_vm._s(props.row.is_operational ? "Yes" : "No"))])]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}],null,false,1092516112)}):_vm._e(),(_vm.viewState == 'Idle' && (!_vm.equipment || _vm.equipment.length == 0))?_c('p',{staticClass:"mx-auto my-16"},[_vm._v(" There is no Equipment on this Contact. ")]):_vm._e(),(_vm.viewState == 'Loading')?_c('div',{staticClass:"flex w-full justify-center py-20"},[_c('Spinner',{attrs:{"color":'text-black',"size":10}})],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center mb-5"},[_c('h3',{staticClass:"uppercase text-lg font-semibold text-primary tracking-wide"},[_vm._v(" Equipment ")]),_c('hr',{staticClass:"flex-1 border-primary mx-4"})])}]

export { render, staticRenderFns }