<template>
  <div class="flex flex-col w-full bg-white p-5 mt-6 mb-6 rounded shadow-md">
    <!-- Title and Header Section -->
    <div class="flex items-center mb-4">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">
        Service Report
      </h3>
      <hr class="flex-grow border-primary border-t-2 mr-4"/>

      <div class="flex items-center">
        <label class="text-sm mr-2">Design Load Capacity:</label>
        <input
            v-model="calibrationData.design_load_capacity"
            type="text"
            class="rounded-l bge-input bge-input-spacing"
            placeholder="Tonne"
            style="text-align: right; width: 200px;"
        >
      </div>
    </div>

    <!-- Service Questions Table -->
    <div class="bg-gray-100 p-4 rounded-md">
      <div class="grid grid-cols-10 rounded-md text-left font-medium bg-gray-200 p-2 uppercase font-semibold">
        <div class="col-span-1">Item</div>
        <div class="col-span-7">Description</div>
        <div class="col-span-2 text-center">Outcome</div>
      </div>

      <!-- Wrapper to handle spacing and hover effect including margin -->
      <div v-for="(item, index) in calibrationData.service_questions" :key="index" class="mt-1">
        <div
            class="grid grid-cols-10 items-center py-2 hover:bg-gray-200  transition-all duration-150">
          <div class="col-span-1 text-left pl-2">{{ index + 1 }}</div>
          <div class="col-span-7 text-left pl-2">{{ item.question_text }}</div>
          <div class="col-span-2 flex justify-center items-center space-x-4">
            <div class="field-radiobutton">
              <RadioButton :id="'pass' + index" :name="'outcome' + index" value="Pass" v-model="item.outcome"
                           class="mr-2"/>
              <label :for="'pass' + index" class="text-gray-600">Pass</label>
            </div>
            <div class="field-radiobutton">
              <RadioButton :id="'fail' + index" :name="'outcome' + index" value="Fail" v-model="item.outcome"
                           class="mr-2"/>
              <label :for="'fail' + index" class="text-gray-600">Fail</label>
            </div>
            <div class="field-radiobutton">
              <RadioButton :id="'na' + index" :name="'outcome' + index" value="N/A" v-model="item.outcome"
                           class="mr-2"/>
              <label :for="'na' + index" class="text-gray-600">N/A</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Further Action Required Section -->
    <div class="mt-4 p-4 bg-white rounded-md">
      <div class="flex items-center justify-end space-x-4">
        <h4 class="font-semibold text-lg text-primary">Further Action Required:</h4>
        <select v-model="calibrationData.action_required"
                class="rounded-lg p-2 border-gray-300 shadow-sm w-32 bge-input">
          <option value="">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  props: {
    calibration_details: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    calibrationData() {

      if (!this.calibration_details || !this.calibration_details.service_report || Object.keys(this.calibration_details.service_report).length === 0) {
        return {
          design_load_capacity: 0,
          service_questions: [],
          action_required: '',
        };
      } else {
        const serviceReport = this.calibration_details.service_report;
        if (serviceReport.action_required == null) {
          serviceReport.action_required = '';
        }
        return serviceReport;
        lk
      }
    }
  },

  methods: {
    getCurrentData() {
      return this.calibrationData;
    }
  },

}
</script>

<style scoped>
.input-style {
  padding: 0.5rem;
  background: white;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}
</style>
