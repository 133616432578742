<template>
  <div class="flex flex-col lg:flex-row w-full  mb-6">
    <!-- Initial Calibration for Smoke Meter -->
    <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:mr-3 rounded shadow-md">
      <div class="flex items-center mb-2">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">Initial Calibration</h3>
        <hr class="flex-grow border-primary border-t-2 mr-4"/>


      </div>
      <div class="bg-gray-100 p-4 rounded-md mt-4">
        <div class="grid grid-cols-4 gap-4 items-center">
          <div class="col-span-2"></div> <!-- Empty spaces for pushing to the right -->
          <div class="text-right font-medium col-span-1">Ambient Temperature:</div>
          <div class="col-span-1">
            <input v-model="calibration_data.initial_calibration.ambient_temperature" type="text"
                   class="mt-1 block w-full rounded-md bge-input-spacing" placeholder="°C"/>
          </div>
        </div>
        <!-- Filters Section -->
        <div class="mt-4">
          <div class="font-medium text-md mb-2"></div>
          <div class="grid grid-cols-3 gap-2 text-center font-medium">
            <div class="col-span-1"></div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">True Filter Value
              (m-1)
            </div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">Smoke Meter Reading
              (m-1)
            </div>
          </div>
          <!-- Garage Filter -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Garage Filter:</div>
            <input v-model="calibration_data.initial_calibration.garage_filter.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.initial_calibration.garage_filter.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 1 -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 1:</div>
            <input v-model="calibration_data.initial_calibration.reference_filter_1.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.initial_calibration.reference_filter_1.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 2 -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 2:</div>
            <input v-model="calibration_data.initial_calibration.reference_filter_2.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.initial_calibration.reference_filter_2.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 3 -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 3:</div>
            <input v-model="calibration_data.initial_calibration.reference_filter_3.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.initial_calibration.reference_filter_3.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

        </div>
      </div>
    </div>

    <!-- Final Calibration for Smoke Meter -->
    <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:ml-3 rounded shadow-md">
      <div class="flex items-center mb-2">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">Final Calibration </h3>
        <hr class="flex-grow border-primary border-t-2 mr-4"/>

        <div class="flex items-center">
          <AppButton color="green" label="Copy" @click="copyInitialToFinal" :icon=true class="mr-2">
            <template v-slot:icon>
              <Icon iconType="copy-plus" class="mr-2"/>
            </template>
          </AppButton>
        </div>

      </div>
      <div class="bg-gray-100 p-4 rounded-md mt-4">
        <div class="grid grid-cols-4 gap-4 items-center">
          <div class="col-span-2"></div> <!-- Empty spaces for alignment -->
          <div class="text-right font-medium col-span-1"></div>
          <div class="col-span-1" style="visibility: hidden;"> <!-- Placeholder for alignment -->
            <input type="text" class="mt-1 block w-full rounded-md bge-input-spacing" placeholder="°C"/>
          </div>
        </div>
        <!-- Repeat Filters Section for Final Calibration -->
        <div class="mt-4">
          <div class="font-medium text-md mb-2"></div>
          <div class="grid grid-cols-3 gap-2 text-center font-medium">
            <div class="col-span-1"></div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">True Filter Value
              (m-1)
            </div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">Smoke Meter Reading
              (m-1)
            </div>
          </div>

          <!-- Garage Filter - Final Calibration -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Garage Filter:</div>
            <input v-model="calibration_data.final_calibration.garage_filter.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.final_calibration.garage_filter.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 1 - Final Calibration -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 1:</div>
            <input v-model="calibration_data.final_calibration.reference_filter_1.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.final_calibration.reference_filter_1.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 2 - Final Calibration -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 2:</div>
            <input v-model="calibration_data.final_calibration.reference_filter_2.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.final_calibration.reference_filter_2.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>

          <!-- Reference Filter 3 - Final Calibration -->
          <div class="grid grid-cols-3 gap-2 mt-2 items-center">
            <div class="text-left font-medium">Reference Filter 3:</div>
            <input v-model="calibration_data.final_calibration.reference_filter_3.true_filter_value"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Value"/>
            <input v-model="calibration_data.final_calibration.reference_filter_3.smoke_meter_reading"
                   type="text" class="col-span-1 rounded-md bge-input-spacing" placeholder="Reading"/>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";

export default {
  components: {AppButton, Icon},
  props: {
    calibration_details: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [notificationMixin],
  computed: {
    calibration_data() {
      return this.calibration_details.smoke_meter || {
        initial_calibration: {
          ambient_temperature: null,
          garage_filter: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_1: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_2: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_3: {
            true_filter_value: null,
            smoke_meter_reading: null
          }
        },
        final_calibration: {
          ambient_temperature: null,
          garage_filter: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_1: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_2: {
            true_filter_value: null,
            smoke_meter_reading: null
          },
          reference_filter_3: {
            true_filter_value: null,
            smoke_meter_reading: null
          }
        }
      }
    }
  },
  methods: {
    getCurrentData() {
      return this.calibration_data;
    },

    async copyInitialToFinal() {
      const confirmation = await this.confirmAction({
        message: 'Do you want to copy initial calibration values to final calibration?',
        header: 'Confirm Copy',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        this.calibration_data.final_calibration = JSON.parse(JSON.stringify(this.calibration_data.initial_calibration));
      }
    },
  }
}
</script>
