<template>
  <button
      :class="buttonClass"
      @click="onClick"
  >
    <slot v-if="icon" name="icon">
    </slot>
    <span>{{ label }}</span>
    <span v-if="badge" :class="['p-badge','p-component', badgeClass, 'p-badge-no-gutter']"
          class="p-badge p-component p-badge-success p-badge-no-gutter ml-2">{{ badge }}</span>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    label: String,
    color: String,
    icon: Boolean,
    badge: [String, Number], // success.info,warning,danger
    badgeType: String
  },
  computed: {
    buttonClass() {
      return [
        'flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1 hover:cursor-pointer',
        `bg-${this.color}-200 hover:bg-${this.color}-300 border-${this.color}-400 hover:border-${this.color}-500`
      ];
    },
    badgeClass() {
      return [
        `p-badge-${this.badgeType}`
      ];
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>
