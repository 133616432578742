<template>
  <div class="flex flex-col w-full bg-white p-5 mt-6  mb-6 rounded shadow-md">
    <!-- Title and Header Section -->
    <div class="flex items-center mb-4">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">
        Calibration
      </h3>
      <hr class="flex-grow border-primary border-t-2 mr-4"/>

      <div class="flex items-center">
        <label class="text-sm mr-2">Ambient Temperature:</label>
        <input
            v-model="calibrationData.ambient_temperature"
            type="text"
            class="rounded-l bge-input bge-input-spacing"
            placeholder="°C"
            style="text-align: right; width: 200px;"
        >
      </div>

    </div>

    <!-- Calibration Data Table -->
    <div class="bg-gray-100 p-4 rounded-md">
      <div class="grid grid-cols-5 rounded-md text-center font-medium bg-gray-200 p-2 uppercase font-semibold"
           style="column-gap: 40px;">
        <div>Inclination Applied (%)</div>
        <div>Initial Reading (%)</div>
        <div class="flex justify-center items-center space-x-2">
          <h2 class="text-center">Final Reading (%)</h2>
          <AppButton color="green" label="" @click="copyInitialToFinal" :icon="true" class="mr-2">
            <template v-slot:icon>
              <Icon iconType="copy-plus" class=""/>
            </template>
          </AppButton>
        </div>
        <div>% Error</div>
        <div>Max Tolerance (%)</div>
      </div>

      <!-- Data Rows -->
      <div v-for="(item, index) in calibrationData.calibration" :key="index"
           class="grid grid-cols-5 text-center mt-4" style="column-gap: 40px;">
        <input type="text" v-model="item.inclination_applied" class="input-style text-gray-500" readonly>
        <input type="text" v-model="item.initial_reading" class="input-style">
        <input type="text" v-model="item.final_reading" class="input-style">
        <input type="text" v-model="item.percent_error" class="input-style">
        <input type="text" v-model="item.max_tolerance" class="input-style text-gray-500" readonly>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {AppButton, Icon},
  mixins: [notificationMixin],
  props: {
    calibration_details: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    calibrationData() {
      // Check if the object is empty and apply default values if necessary

      if (!this.calibration_details || !this.calibration_details.decelerometer || Object.keys(this.calibration_details).length === 0) {
        return {
          ambient_temperature: 0,  // Default ambient temperature if not provided
          calibration: [
            {
              inclination_applied: 0,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '0.00 - 0.00'
            },
            {
              inclination_applied: 16,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '15.52 - 16.48'
            },
            {
              inclination_applied: 25,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '24.25 - 25.75'
            },
            {
              inclination_applied: 50,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '48.50 - 51.50'
            },
            {
              inclination_applied: 80,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '77.60 - 82.40'
            },
            {
              inclination_applied: 100,
              initial_reading: 0,
              final_reading: 0,
              percent_error: 0,
              max_tolerance: '98.00 - 103.00'
            }
          ]
        };
      } else {
        return this.calibration_details.decelerometer;
      }
    }
  },
  methods: {
    getCurrentData() {
      // This method can be used to fetch the latest data
      return this.calibrationData;
    },

    async copyInitialToFinal() {
      const confirmation = await this.confirmAction({
        message: 'Do you want to copy initial calibration values to final calibration for the Decelerometer?',
        header: 'Confirm Copy',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        const data = JSON.parse(JSON.stringify(this.calibrationData));
        data.calibration.forEach(entry => {

          entry.final_reading = entry.initial_reading;
        });
      }
    },
  }
}
</script>


<style scoped>
.input-style {
  padding: 0.5rem;
  background: white;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}
</style>

