<template>
  <div class="flex flex-col lg:flex-row w-full  mb-6">
    <!-- Column 1 -->
    <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:mr-3 rounded shadow-md">
      <div class="flex items-center mb-2">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">
          Initial Calibration
        </h3>

        <hr class="flex-grow border-primary border-t-2 mr-4"/>

        <div class="flex items-center">
          <label class="text-sm mr-2">Gas Bench PEF</label>
          <input
              v-model="calibration_data.initial_calibration.pef"
              type="text"
              class="rounded-l bge-input bge-input-spacing"
              placeholder="PEF Value"
          />
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-md mt-4">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label class="block text-sm font-medium">Ambient Pressure:</label>
            <input
                v-model="calibration_data.initial_calibration.ambient_pressure"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
                placeholder="mbar"
            />
          </div>
          <div>
            <label class="block text-sm font-medium">Ambient Temperature:</label>
            <input
                v-model="calibration_data.initial_calibration.ambient_temperature"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
                placeholder="°c"
            />
          </div>
          <div>
            <label class="block text-sm font-medium">Gas Bottle Number:</label>
            <input
                v-model="calibration_data.initial_calibration.gas_bottle_number"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
            />
          </div>
          <div>
            <label class="block text-sm font-medium">Final Ambient O2 Reading:</label>
            <input
                v-model="calibration_data.initial_calibration.final_ambient_o2_reading"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
                placeholder="%"
            />
          </div>
        </div>

        <!-- Gas Readings -->
        <div class="mt-4">
          <div class="grid grid-cols-6 gap-2 text-center font-medium">
            <div class="col-span-2 rounded-md p-2"></div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">CO</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">HC</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">CO2</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">O2</div>
          </div>

          <div class="grid grid-cols-6 gap-2 mt-2">
            <div class="col-span-2 rounded-md p-2 text-left">Calibration Filter:</div>
            <input
                v-model="calibration_data.initial_calibration.calibration_gas.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.calibration_gas.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.calibration_gas.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.calibration_gas.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>

          <div class="mt-2 grid grid-cols-6 gap-2">
            <div class="col-span-2 rounded-md p-2 text-left">Gas x PEF:</div>
            <input
                v-model="calibration_data.initial_calibration.gas_pef.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.gas_pef.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.gas_pef.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.gas_pef.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>

          <div class="mt-2 grid grid-cols-6 gap-2">
            <div class="col-span-2 rounded-md p-2 text-left">Analyser Reading:</div>
            <input
                v-model="calibration_data.initial_calibration.analyser_reading.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.analyser_reading.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.analyser_reading.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.initial_calibration.analyser_reading.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>
        </div>
      </div>
    </div>


    <!-- Column 2 -->
    <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:ml-3 rounded shadow-md">
      <div class="flex items-center mb-2">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">
          Final Calibration
        </h3>
        <hr class="flex-grow border-primary border-t-2 mr-4"/>
        <div class="flex items-center">
          <AppButton color="green" label="Copy" @click="copyInitialToFinalExhaustGasAnalyser" :icon=true class="mr-2">
            <template v-slot:icon>
              <Icon iconType="copy-plus" class="mr-2"/>
            </template>
          </AppButton>
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-md mt-8">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label class="block text-sm font-medium" style="visibility: hidden;">Placeholder:</label>
            <input type="text" class="mt-1 block w-full rounded-md bge-input-spacing" style="visibility: hidden;"/>
          </div>
          <div>
            <label class="block text-sm font-medium" style="visibility: hidden;">Placeholder:</label>
            <input type="text" class="mt-1 block w-full rounded-md bge-input-spacing" style="visibility: hidden;"/>
          </div>
          <div>
            <label class="block text-sm font-medium">Gas Bottle Number:</label>
            <input
                v-model="calibration_data.final_calibration.gas_bottle_number"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
            />
          </div>
          <div>
            <label class="block text-sm font-medium">Final Ambient O2 Reading:</label>
            <input
                v-model="calibration_data.final_calibration.final_ambient_o2_reading"
                type="text"
                class="mt-1 block w-full rounded-md bge-input-spacing"
                placeholder="%"
            />
          </div>
        </div>

        <!-- Gas Readings -->
        <div class="mt-4">
          <div class="grid grid-cols-6 gap-2 text-center font-medium">
            <div class="col-span-2 rounded-md p-2"></div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">CO</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">HC</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">CO2</div>
            <div class="col-span-1 rounded-md bg-gray-200 p-2 uppercase font-semibold text-md">O2</div>
          </div>

          <div class="grid grid-cols-6 gap-2 mt-2">
            <div class="col-span-2 rounded-md p-2 text-left">Calibration Filter:</div>
            <input
                v-model="calibration_data.final_calibration.calibration_gas.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.calibration_gas.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.calibration_gas.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.calibration_gas.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>

          <div class="mt-2 grid grid-cols-6 gap-2">
            <div class="col-span-2 rounded-md p-2 text-left">Gas x PEF:</div>
            <input
                v-model="calibration_data.final_calibration.gas_pef.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.gas_pef.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.gas_pef.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.gas_pef.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>

          <div class="mt-2 grid grid-cols-6 gap-2">
            <div class="col-span-2 rounded-md p-2 text-left">Analyser Reading:</div>
            <input
                v-model="calibration_data.final_calibration.analyser_reading.co"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.analyser_reading.hc"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.analyser_reading.co2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
            <input
                v-model="calibration_data.final_calibration.analyser_reading.o2"
                type="text"
                class="col-span-1 rounded-md bge-input-spacing"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {AppButton, Icon},
  mixins: [notificationMixin],
  props: {
    calibration_details: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    calibration_data() {
      return this.calibration_details.exhaust_gas_analyser || {
        initial_calibration: {
          pef: '',
          ambient_pressure: '',
          ambient_temperature: '',
          gas_bottle_number: '',
          final_ambient_o2_reading: '',
          calibration_gas: {co: '', hc: '', co2: '', o2: ''},
          gas_pef: {co: '', hc: '', co2: '', o2: ''},
          analyser_reading: {co: '', hc: '', co2: '', o2: ''}
        },
        final_calibration: {
          gas_bottle_number: '',
          final_ambient_o2_reading: '',
          calibration_gas: {co: '', hc: '', co2: '', o2: ''},
          gas_pef: {co: '', hc: '', co2: '', o2: ''},
          analyser_reading: {co: '', hc: '', co2: '', o2: ''}
        }
      };
    }
  },
  methods: {
    getCurrentData() {
      return this.calibration_data;
    },
    async copyInitialToFinalExhaustGasAnalyser() {
      const confirmation = await this.confirmAction({
        message: 'Do you want to copy initial calibration values to final calibration for Exhaust Gas Analyser?',
        header: 'Confirm Copy',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        const initial = this.calibration_data.initial_calibration;
        const finalCalibration = this.calibration_data.final_calibration;
        finalCalibration.gas_bottle_number = initial.gas_bottle_number;
        finalCalibration.final_ambient_o2_reading = initial.final_ambient_o2_reading;
        finalCalibration.calibration_gas = JSON.parse(JSON.stringify(initial.calibration_gas));
        finalCalibration.gas_pef = JSON.parse(JSON.stringify(initial.gas_pef));
        finalCalibration.analyser_reading = JSON.parse(JSON.stringify(initial.analyser_reading));
      }
    },
  }
}
</script>
