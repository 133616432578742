<template>
  <div class="flex flex-col w-full bg-white p-5 mt-6 mb-6 rounded shadow-md">
    <!-- Title and Header Section -->
    <div class="flex items-center mb-4">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide mr-4">
        Calibration
      </h3>
      <hr class="flex-grow border-primary border-t-2 mr-4"/>

      <div class="flex items-center">
        <label class="text-sm mr-2">Ambient Temperature:</label>
        <input
            v-model="calibrationData.ambient_temperature"
            type="text"
            class="rounded-l bge-input bge-input-spacing"
            placeholder="°C"
            style="text-align: right; width: 200px;"
        >
      </div>
    </div>

    <!-- Calibration Data Table -->
    <div class="bg-gray-100 p-4 rounded-md">
      <div class="grid grid-cols-8 rounded-md text-center font-medium bg-gray-200 p-2 uppercase font-semibold"
           style="column-gap: 20px;">
        <div>Weight Applied (kg)</div>
        <div>Kgf Force Applied</div>
        <div>Reading Left (kgf)</div>
        <div>% Error Left (max 3%)</div>
        <div>Reading Right (kgf)</div>
        <div>% Error Right (max 3%)</div>
        <div>Error Tolerance</div>
        <div>Weight Scales (kg)</div>
      </div>

      <!-- Data Rows -->
      <div v-for="(item, index) in calibrationData.calibration" :key="index"
           class="grid grid-cols-8 text-center mt-4" style="column-gap: 20px;">
        <input type="text" v-model="item.weight_applied" class="input-style text-gray-500" readonly>
        <input type="text" v-model="item.kgf_force_applied" class="input-style text-gray-500" readonly>
        <input type="text" v-model="item.reading_left" class="input-style">
        <input type="text" v-model="item.percent_error_left" class="input-style">
        <input type="text" v-model="item.reading_right" class="input-style">
        <input type="text" v-model="item.percent_error_right" class="input-style">
        <input type="text" v-model="item.error_tolerance" class="input-style text-gray-500" readonly>
        <input type="text" v-model="item.weight_scales" class="input-style">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    calibration_details: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    calibrationData() {
      // Check if the object is empty and apply default values if necessary
      if (!this.calibration_details || !this.calibration_details.roller_brake_tester || Object.keys(this.calibration_details).length === 0) {
        return {
          ambient_temperature: 0,  // Default ambient temperature if not provided
          calibration: [
            {
              weight_applied: 0,
              kgf_force_applied: 0,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '0',
              weight_scales: 0
            },
            {
              weight_applied: 5,
              kgf_force_applied: 50,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '48 - 52',
              weight_scales: 0
            },
            {
              weight_applied: 10,
              kgf_force_applied: 100,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '97 - 103',
              weight_scales: 0
            },
            {
              weight_applied: 20,
              kgf_force_applied: 200,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '194 - 206',
              weight_scales: 0
            },
            {
              weight_applied: 40,
              kgf_force_applied: 400,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '388 - 412',
              weight_scales: 0
            },
            {
              weight_applied: 50,
              kgf_force_applied: 500,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '485 - 515',
              weight_scales: 0
            },
            {
              weight_applied: 60,
              kgf_force_applied: 600,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '582 - 618',
              weight_scales: 0
            },
            {
              weight_applied: 70,
              kgf_force_applied: 700,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '679 - 721',
              weight_scales: 0
            },
            {
              weight_applied: 80,
              kgf_force_applied: 800,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '776 - 824',
              weight_scales: 0
            },
            {
              weight_applied: 100,
              kgf_force_applied: 1000,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '970 - 1030',
              weight_scales: 0
            },
            {
              weight_applied: 120,
              kgf_force_applied: 1200,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '1164 - 1236',
              weight_scales: 0
            },
            {
              weight_applied: 150,
              kgf_force_applied: 1500,
              reading_left: 0,
              reading_right: 0,
              percent_error_left: 0,
              percent_error_right: 0,
              error_tolerance: '1212 - 1287',
              weight_scales: 0
            }
          ]
        };
      } else {
        return this.calibration_details.roller_brake_tester;
      }
    }
  },
  methods: {
    getCurrentData() {
      // This method can be used to fetch the latest data
      return this.calibrationData;
    }
  }
}
</script>

<style scoped>
.input-style {
  padding: 0.5rem;
  background: white;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}
</style>
